import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { SwipeableDrawer, IconButton, List, ListItem, ListItemText } from "@material-ui/core";
import { Menu as MenuIcon } from "mdi-material-ui";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  menuIcon: {
    color: theme.palette.primary.contrastText
  }
}));

export default function MenuMobile(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const {
    site: {
      siteMetadata: { menuLinks }
    }
  } = useStaticQuery(graphql`
    query MenuMobileQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            to
          }
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <IconButton onClick={handleOpen}>
        <MenuIcon className={classes.menuIcon} />
      </IconButton>
      <SwipeableDrawer
        anchor="top"
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={handleClose}
          onKeyDown={handleClose}
        >
          <List>
            {menuLinks.map(({ name, to }) => (
              <ListItem
                button
                key={name}
                component={Link}
                to={to}
              >
                <ListItemText primary={name} />
              </ListItem>
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
