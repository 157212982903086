import React from "react";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";
import { Hidden, Grid, Toolbar, AppBar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  pageTitle: {
    color: theme.palette.primary.contrastText
  }
}));

export default function Header({
  stickyAppBar,
  title
}) {
  const classes = useStyles();
  return (
    <AppBar
      position={stickyAppBar ? 'sticky': 'fixed'}
    >
      <Toolbar variant="dense">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={16}
        >
          <Grid item>
            {title && <Typography variant="h6" component="h1" className={classes.pageTitle}>
              {title}
            </Typography>}
          </Grid>
          <Grid item>
            <Hidden xsDown>
              <Menu />
            </Hidden>
            <Hidden smUp>
              <MenuMobile />
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
