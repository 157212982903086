import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.contrastText,
    '&:not(:last-child)': {
      marginRight: theme.spacing.unit
    }
  }
}));

export default function Menu() {
  const {
    site: {
      siteMetadata: { menuLinks }
    }
  } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            to
          }
        }
      }
    }
  `);
  const classes = useStyles();
  return (
    <React.Fragment>
      {menuLinks.map(({
        name,
        to
      }) => (
        <Button
          key={name}
          to={to}
          component={Link}
          className={classes.link}
        >
          {name}
        </Button>
      ))}
    </React.Fragment>
  );
};
