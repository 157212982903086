import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../css/style.styl";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  page: {
    height: '100vh',
    minHeight: 'fit-content',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 1 auto'
  }
});

export default function Page({ children, stickyAppBar, title }) {
  const classes = useStyles();
  return (
    <div className={classes.page}>
      <Header stickyAppBar={stickyAppBar} title={title} />
      <div className={classes.content}>
        {children}
      </div>
      <Footer />
    </div>
  );
};
