import React from "react";
import PropTypes from 'prop-types';
import { Link } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import {
  EmailOutline as EmailOutlineIcon,
  Skype as SkypeIcon,
  Github as GithubIcon,
  Npm as NpmjsIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  Linkedin as LinkedinIcon
} from 'mdi-material-ui';

export const RESOURCE_TYPES = {
  EMAIL: 'EMAIL',
  SKYPE: 'SKYPE',
  NPMJS: 'NPMJS',
  GITHUB: 'GITHUB',
  TWITTER: 'TWITTER',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN'
};

const RESOURCE_PARAMS = {
  [RESOURCE_TYPES.EMAIL]: {
    Icon: EmailOutlineIcon,
    getHref: email => `mailto:${email}`,
    target: '_self'
  },
  [RESOURCE_TYPES.SKYPE]: {
    Icon: SkypeIcon,
    getHref: skypeId => `skype:${skypeId}?chat`,
    target: '_self'
  },
  [RESOURCE_TYPES.GITHUB]: {
    Icon: GithubIcon,
    getHref: userId => `https://github.com/${userId}`,
    target: '_blank'
  },
  [RESOURCE_TYPES.NPMJS]: {
    Icon: NpmjsIcon,
    getHref: userId => `https://www.npmjs.com/~${userId}`,
    target: '_blank'
  },
  [RESOURCE_TYPES.TWITTER]: {
    Icon: TwitterIcon,
    getHref: userId => `https://twitter.com/${userId}`,
    target: '_blank'
  },
  [RESOURCE_TYPES.INSTAGRAM]: {
    Icon: InstagramIcon,
    getHref: userId => `https://www.instagram.com/${userId}/`,
    target: '_blank'
  },
  [RESOURCE_TYPES.LINKEDIN]: {
    Icon: LinkedinIcon,
    getHref: userId => `https://www.linkedin.com/in/${userId}/`,
    target: '_blank'
  }
};

const useStyles = makeStyles({
  icon: {
    verticalAlign: 'middle'
  }
});

export default function ExternalLink({
  color,
  type,
  value
}) {
  const classes = useStyles();
  const {
    Icon,
    getHref = v => v,
    target = '_blank'
  } = RESOURCE_PARAMS[type] || {};
  return (
    <Link href={getHref(value)} color={color} target={target}>
      {Icon && <Icon fontSize="inherit" className={classes.icon} />}
      {' '}
      {value}
    </Link>
  );
}

ExternalLink.propTypes = {
  color: PropTypes.string,
  type: PropTypes.oneOf(Object.values(RESOURCE_TYPES)).isRequired,
  value: PropTypes.string.isRequired
};

ExternalLink.defaultProps = {
  color: 'inherit'
};
