import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Divider, Hidden, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import ExternalLink, { RESOURCE_TYPES } from './ExternalLink';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing.unit * 6,
    marginBottom: theme.spacing.unit * 3,
  },
  footer: {
    marginBottom: theme.spacing.unit * 3,
    whiteSpace: 'nowrap',
  },
  inlineIcon: {
    verticalAlign: 'middle'
  }
}));

function Footer(props) {
  const {
    data: {
      file: {
        childAssetsJson: {
          name,
          title,
          contacts: { email, skype },
          copyrightYear
        },
      },
    },
  } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <footer
        className={classes.footer}
        id="footer"
      >
        <Divider className={classes.divider} />
        <span>
          <Typography variant="caption" component="span">
            {`© ${copyrightYear} ${name} - ${title} `}
            <Hidden smDown>{' - '}</Hidden>
            <Hidden mdUp>
              <br />
            </Hidden>
            {' '}
            <ExternalLink value={email} type={RESOURCE_TYPES.EMAIL} />
            {!!skype && (
              <>
              {' - '}
              <ExternalLink value={skype} type={RESOURCE_TYPES.SKYPE} />
              </>
            )}
          </Typography>
        </span>
      </footer>
    </React.Fragment>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "info.json" }, sourceInstanceName: { eq: "assets" }) {
          childAssetsJson {
            copyrightYear
            name
            title
            contacts {
              email
              skype
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
